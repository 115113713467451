.main-ficha-libro {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
   /*Distribuir el espacio entre los elementos */
  min-height: 625px;
  width: 80%;
  padding: 10px 20px;
  background-color: antiquewhite;
  margin-left: 10%;
  height: auto;
}

.content-principal-serie {
  display: flex;  
  flex-wrap: wrap;
  align-items: flex-start; 
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 20px 10px 10px 10px;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 12px;
  margin: 10px 10px 50px 0;  
  background-color: #ffffff;
  box-shadow: 0 3px 13px 1px rgb(0 0 0 / 9%);
}

.content-image-libro {
  width: 300px;
  height: auto;
  text-align: center;
}

.content-image-libro img{
  width: 300px;
  height: auto;
}

.content-descripcion {
  display: flex; /* Para que los dos primeros elementos estén en una fila */
  flex-direction: column;
  justify-content: center; /* Centrar horizontalmente los elementos */   
  width: 700px; 
  padding: 0px 5px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
}

.content-descripcion h2 {
  margin-bottom: 10px;
}

.content-descripcion h3{
  margin: 10px 0;
}

.button-serie {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.content-serie-button{
  width: 100%;
  display: flex; /* Para que los dos primeros elementos estén en una fila */
  justify-content: center; /* Centrar horizontalmente los elementos */ 
  padding-top: 30px;
}

@media screen and (max-width: 600px) {
  .content-image-libro {
    width: 100%;
  }
  .content-image-libro img{
    max-width: 300px;
    width: 100%;
  }

  .content-principal-serie {

  }

  .main-ficha-libro {
    width: 100%;
    padding: 10px 10px;
    margin-left: 0;
  }

  .content-descripcion h2 {
    margin-top: 25px;
  }

}