* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {  
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-image: url('imagenes/6832784.jpg');
  background-repeat: repeat;
  background-size: 200px 200px;
}

.contenedor-titulo-libros{
  width: 100%;
  border-bottom: 2px solid gray;
  margin-top: 30px;
}

.titulos-libros{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande';
  font-size: 1.5em;
}