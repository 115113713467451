.main-juegos {
  min-height: 628px;
  width: 80%;
  padding: 10px 20px;
  background-color: antiquewhite;
  margin-left: 10%;
  height: auto;
}

.contenedor-juegos{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

