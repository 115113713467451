.main-cookies {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  /* Distribuir el espacio entre los elementos */
  min-height: 628px;
  width: 80%;
  padding: 10px 20px;
  background-color: antiquewhite;
  margin-left: 10%;
  height: auto;
}

.main-cookies h3{
  margin: 20px 0 10px 0;
}

.main-cookies h2, .main-cookies h1{
  margin: 10px 0;
  text-align: center;
}