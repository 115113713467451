footer {  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;  
  height: 80px;  
  background-color: black;
  text-align: center;
}

footer p {
  color: white;
  font-size: 1.5em; 
}



@media screen and (max-width: 600px) {
  footer p {
    font-size: 1em;
  }
}