.content-libro {
  width: 200px;
  height: 360px;
  text-align: center;
  background-color: #f0f0f5;
  margin: 20px 0 0 0;
  border-top: 5px solid blueviolet;
  border-bottom: 5px solid blueviolet;
  border-left: 1px solid rgb(232, 232, 232);
  border-right: 1px solid rgb(232, 232, 232);  
  box-shadow: 10px 10px 5px #888888;
}

.content-libro:hover {
  background-color: #fff;
  border-top: 5px solid red;
  border-bottom: 5px solid red;
}

.content-libro-img {
  width: 200px;
  /* Ancho fijo */
  height: 300px;
  /* Alto fijo */
  overflow: hidden;
  /* Oculta parte de la imagen que se desborda */
}

.content-libro-img img {
  width: 100%;
  /* Para asegurar que la imagen ocupe todo el espacio */
  height: 100%;
  /* Para asegurar que la imagen ocupe todo el espacio */
  object-fit: cover;
  /* Escala la imagen manteniendo su relación de aspecto, cubriendo todo el contenedor */
}

.content-libro-titulo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.content-libro span {  
  color: black;
  font-weight: bold;
}


.content-libro a {
  text-decoration: none;
}