.main-not-found-page {
  min-height: 628px;
  width: 80%;
  padding: 10px 20px;
  background-color: antiquewhite;
  margin-left: 10%;
  height: auto;
}

.content-404{
  padding-top: 50px;
  font-family: fantasy;
  text-align: center;
}

.content-404 img{
  max-width: 500px;
  width: 80%;
  height: auto;
}

.main-not-found-page span {
  font-size: 50px;
  color: red;
  font-weight: bold;
}