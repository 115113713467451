header {
  display: flex;
  justify-content: space-between;  
  width: 100%;
  height: auto;
  background-color: rgb(38, 53, 74);
}

header h1 {  
  font-family: OldEnglish;
  font-size: 2em;
  font-weight: bold;
  color: gold;  
  margin: 0;
  padding-left: 10px;
}

header h2 {  
  font-family: LucidaCalligraphyCursiva;
  color: #FFD5D5;
  font-size: 1.1em;
  font-weight: bold;  
  margin: 0;
  padding:14px 0 0 15px;
}

#titulosHead{
  display: flex;  
  align-items: center;  
  justify-content: center;
}

#socialHeader {  
  height: 50px;
  margin-right: 20px;
}

.socialIcono:hover {
  color: #ff00ff;
}

.socialIcono {
  font-size: 30px;
  color: white;
  margin-right: 20px;
  margin-top: 10px;
}


#logoini {
  float: left;
  width: 46px;
  height: 46px;  
  margin-left: 5px;
}

@font-face {
  font-family: OldEnglish;
  src: url(../fuentes/OLDENGL.TTF);
}

@font-face {
  font-family: LucidaCalligraphyCursiva;
  src: url(../fuentes/LCALLIG.TTF);
}

@media screen and (max-width: 905px) {
  header h2 { 
    display: none;
  }
}

@media screen and (max-width: 700px) {
  
}

@media screen and (max-width: 500px) {
  header{        
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }
  
  #titulosHead {
    width: 100%;
    align-items: center;
    margin-top: 6px;
  }
  
  header h1{    
    font-size: 40px;
    letter-spacing: -1px;
    padding-left: 5px;
    
  }

  header h2 { 
    display: none;
  }

  #socialHeader { 
    width: 100%;
    text-align: center;
    margin-right: 0;
  }

  .socialIcono {
    font-size: 30px;    
    margin-right: 10px;
    margin-top: 10px;
  }

  #socialHeader a:last-child .socialIcono{
    margin-right: 0;
  }
} 