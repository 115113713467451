.main-admin {
  min-height: 628px;
  width: 80%;
  padding: 10px 20px;
  background-color: antiquewhite;
  margin-left: 10%;
  height: auto;
}

.admin-button {
  margin-top: 20px;
  width: 150px;
  padding: 10px;
  background-color: #7334cb;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-button:hover {
  background-color: #5c2aa2;
}