.main-home-page {
  /*display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
   Distribuir el espacio entre los elementos */
  min-height: 628px;
  width: 80%;
  padding: 10px 20px;
  background-color: antiquewhite;
  margin-left: 10%;
  height: auto;
}


.contenedor-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media screen and (max-width: 900px) {
  .contenedor-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 700px) {
  .contenedor-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .contenedor-cards {
    grid-template-columns: 1fr;
  }

  .main-home-page {
    width: 100%;
    padding: 10px 20px;    
    margin-left: 0;
  }  
}