.contenedor-principal-destacado {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding-bottom: 15px;
  margin: 15px 0;
  justify-content: center;
   
  border-bottom:2px solid rgb(192, 192, 192); 
  
  
}



.contenedor-destacado-foto{
  width: 300px;
  height: auto;
}

.contenedor-destacado-foto img{
  width: 250px;
  height: auto;
}



.contenedor-destacado-texto{
  width: 60%;
  height: auto;  
  
}
.contenedor-destacado-texto h1{
  text-align: center;
  color: rgb(86, 4, 22);
}

.contenedor-destacado-texto h2{
  margin: 20px 0;
  text-decoration: underline;
}

.contenedor-destacado-texto p{
  font-size: 1.5rem;
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .contenedor-destacado-foto {
    width: 100%;
    text-align: center;
  }
  .contenedor-destacado-texto {
    width: 100%;
  }
}



