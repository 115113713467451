.content-producto {
    width: 260px;
    height: 360px;
    text-align: center;
    background-color: #f0f0f5;
    margin: 10px 0 10px 0;
    border-top: 5px solid blueviolet;
    border-bottom: 5px solid blueviolet;
    border-left: 1px solid rgb(232, 232, 232);
    border-right: 1px solid rgb(232, 232, 232);  
    box-shadow: 10px 10px 5px #888888;
  }
  
  .content-producto:hover {
    background-color: #fff;
    border-top: 5px solid red;
    border-bottom: 5px solid red;
  }
  
  .content-producto-img {
    display: flex;
    width: 258px;
    /* Ancho fijo */
    height: 258px;
    /* Alto fijo */
    overflow: hidden;
    /* Oculta parte de la imagen que se desborda */
    padding: 3px 0 3px 0;
    background-color: white; 
    align-items: center; /* Centra verticalmente */   
    justify-content: center;
  }
  
  .producto-img{
    width: auto;
    /* Para asegurar que la imagen ocupe todo el espacio */
    height: 100%;
    /* Para asegurar que la imagen ocupe todo el espacio */
    object-fit: cover;
    /* Escala la imagen manteniendo su relación de aspecto, cubriendo todo el contenedor */
    
  }

  .producto-img-h{
    width: 100%;
    /* Para asegurar que la imagen ocupe todo el espacio */
    height: auto;
    /* Para asegurar que la imagen ocupe todo el espacio */
    object-fit: cover;
    /* Escala la imagen manteniendo su relación de aspecto, cubriendo todo el contenedor */
    
  }
  
  .content-producto-titulo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 91px;
  }
  
  .content-producto span {  
    color: black;
    font-weight: bold;
  }
  
  
  .content-producto a {
    text-decoration: none;
  }