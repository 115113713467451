.card {
  border-radius: 10px;
  min-height: 200px;
  font-weight: bold;
  padding: 20px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  font-family: 'Roboto', sans-serif;
}

.card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* color negro con 50% de opacidad */
}

.card:hover::after {
  background-color: rgba(0, 0, 0, 0.2);
}

.card .textos-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;


}

.textos-card h3,
.textos-card p {
  color: white;
  -webkit-text-stroke: 0.5px black;
  z-index: 1;
}

.textos-card h3 {
  font-size: 25px;
}

.textos-card p {
  font-size: 20px;
}

.urlCard {    
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  text-decoration: none;
}