.main-personalizados {
  display:flex;
  flex-wrap: wrap;
  align-items: flex-start;  
  justify-content: space-between; /* Distribuir el espacio entre los elementos */
  min-height: 628px;
  width: 80%;
  padding: 10px 20px;
  background-color: antiquewhite;
  margin-left: 10%;
  height: auto;
}

.titulo1 {
  margin: 10px 0;
  font-size: 2em;
  color: blueviolet;
}

.titulo2 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: black;
}

