.contenedor-form-pers {
  width: 70%;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

#formulario * {
  box-sizing: border-box;
}

#formulario input[type=text],
#formulario [type=number],
#formulario [type=email],
#formulario [type=file],
#formulario select,
#formulario textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

#formulario label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

#formulario input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  margin-top: 12px;
}

#formulario input[type=submit]:hover {
  background-color: #45a049;
}

#formulario .col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

#formulario .col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
#formulario .row:after {
  content: "";
  display: table;
  clear: both;
}

#infoFormMail {
  color: red;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

  #formulario .col-25,
  #formulario .col-75,
  #formulario input[type=submit], 
  #formulario input[type=text],
  #formulario [type=number],
  #formulario [type=email],
  #formulario [type=file],
  #formulario select,
  #formulario textarea{
    width: 100%;
    margin-top: 12;
  }

  .contenedor-form-pers{
    width: 100%;
  }
}