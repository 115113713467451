.topnav {
  width: 100%;
  overflow: hidden;
  background-color: rgb(56, 25, 98);  
  background-image: linear-gradient(180deg, #335599, #4c5465);
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.active {
  background-color: purple;
  color: white;
  font-weight: bold;
}

.topnav .icon {
  display: none;
}


@media screen and (max-width: 900px) {
  
}

@media screen and (max-width: 700px) {
  
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }
  
  .topnav.responsive {
    position: relative;    
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a{
    float: none;
    display: block;
    text-align: center;
  }
}



